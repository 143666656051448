<template>
    <div>
        <b-card no-body>
            <b-card-header class="d-flex justify-content-between">
                <h4 class="my-0">{{ $t('pages.vdi.rdsh.headers.settings') }}</h4>
                <b-button size="sm" variant="success" @click="saveApp">{{ $t('buttons.save') }}</b-button>
            </b-card-header>
            <b-overlay :show="loadingApp" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                <b-card-body>
                    <b-form>
                        <b-form-group
                            label-cols-xl="3"
                            label="RDP-File Template"
                        >
                            <template #label>
                                {{ $t('pages.vdi.rdsh.settings.rdpTemplate.label') }}<br>
                                <span class="small">
                                    {{ $t('pages.vdi.rdsh.settings.rdpTemplate.description') }}
                                </span>
                            </template>
                            <vue-codeditor style="font-size: 14px" v-model="rdsh.rdpTemplate" mode="eiffel" theme="monokai"/>
                        </b-form-group>
                    </b-form>

                    <b-form-group
                        label="Variablen definieren"
                    >
                        <template #label>
                            <strong>{{ $t('pages.vdi.pools.settings.variables.label') }}</strong>
                            <div>
                                {{ $t('pages.vdi.pools.settings.variables.defaultVariables.description') }}:<br>
                                <ul>
                                    <li>{userName} = {{ $t('pages.vdi.pools.settings.variables.defaultVariables.username') }}</li>
                                </ul>
                            </div>
                        </template>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(rdshAddVar)">

                                <b-table-simple small striped>
                                    <b-thead>
                                        <b-tr>
                                            <b-th>{{ $t('pages.vdi.rdsh.settings.variables.table.german') }}</b-th>
                                            <b-th>{{ $t('pages.vdi.rdsh.settings.variables.table.english') }}</b-th>
                                            <b-th>{{ $t('pages.vdi.rdsh.settings.variables.table.name') }}</b-th>
                                            <b-th>{{ $t('pages.vdi.rdsh.settings.variables.table.value') }}</b-th>
                                            <b-th></b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr>
                                            <b-td>
                                                <validation-provider
                                                    :rules="{ required: true }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-input
                                                        size="sm"
                                                        :state="getValidationState(validationContext)"
                                                        v-model="rdsh.first.de"
                                                    />
                                                </validation-provider>
                                            </b-td>
                                            <b-td>
                                                <validation-provider
                                                    :rules="{ required: true }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-input
                                                        size="sm"
                                                        :state="getValidationState(validationContext)"
                                                        v-model="rdsh.first.en"
                                                    />
                                                </validation-provider>
                                            </b-td>
                                            <b-td>
                                                <validation-provider
                                                    :rules="{ required: true }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-input
                                                        size="sm"
                                                        :state="getValidationState(validationContext)"
                                                        v-model="rdsh.first.name"
                                                    />
                                                </validation-provider>
                                            </b-td>
                                            <b-td>
                                                <b-form-input
                                                    size="sm"
                                                    v-model="rdsh.first.value"
                                                />
                                            </b-td>
                                            <b-td>
                                                <b-button variant="success" size="sm" type="submit">
                                                    <font-awesome-icon icon="plus"/>
                                                </b-button>
                                            </b-td>
                                        </b-tr>
                                        <b-tr v-for="(val, key) in rdsh.vars" :key="key">
                                            <b-td>
                                                <b-form-input
                                                    size="sm"
                                                    :state="val.de !== ''"
                                                    v-model="val.de"
                                                    @keypress.prevent.stop.enter
                                                />
                                            </b-td>
                                            <b-td>
                                                <b-form-input
                                                    size="sm"
                                                    :state="val.en !== ''"
                                                    v-model="val.en"
                                                    @keypress.prevent.stop.enter
                                                />
                                            </b-td>
                                            <b-td>
                                                <b-form-input
                                                    size="sm"
                                                    :state="val.name !== ''"
                                                    v-model="val.name"
                                                    @keypress.prevent.stop.enter
                                                />
                                            </b-td>
                                            <b-td>
                                                <b-form-input
                                                    size="sm"
                                                    v-model="val.value"
                                                    @keypress.prevent.stop.enter
                                                />
                                            </b-td>
                                            <b-td>
                                                <b-button variant="danger" size="sm" type="button" @click="rdshDeleteVar(key)">
                                                    <font-awesome-icon icon="minus"/>
                                                </b-button>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-form>
                        </validation-observer>
                    </b-form-group>
                </b-card-body>
            </b-overlay>
            <b-card-footer class="text-right">
                <b-button variant="success" @click="saveApp">{{ $t('buttons.save') }}</b-button>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import api from "../../../services/api";
import rdshService from "../../../services/rdshService";

export default {
    name: "RdshSettings",
    data: () => ({
        rdsh: {
            rdpTemplate: '',
            first: {
                de: '',
                en: '',
                name: '',
                value: ''
            },
            vars: []
        },
        loadingApp: false
    }),
    mounted() {
        this.loadDefault();
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        loadDefault() {
            api().get("settings/pools/rdshs").then(response => {
                this.rdsh.rdpTemplate = response.data.rdpTemplate;
                this.rdsh.vars = response.data.vars;
            }).catch(() => {
            });
        },
        rdshAddVar() {
            if (this.rdsh.first.de === '' || this.rdsh.first.en === '' || this.rdsh.first.name === '') {
                this.$toastr.e(this.$t('pages.admin.settings.messages.error.requiredFields'));
                return false;
            }
            this.rdsh.vars.push(Object.assign({}, this.rdsh.first));
            this.rdsh.first = {
                de: '',
                en: '',
                name: '',
                value: ''
            };
        },
        rdshDeleteVar(index) {
            this.rdsh.vars.splice(index, 1);
        },
        saveApp() {
            let data = Object.assign({}, this.rdsh);
            delete data.first;
            this.loadingApp = true;
            api().put('settings/pools/rdshs', data).then(() => {
                this.loadingApp = false;
                this.$toastr.s(this.$t('pages.admin.settings.messages.success.saved'));
            }).catch(error => {
                this.loadingApp = false;
                this.errorHandler(error);
            });
        }
    }

};
</script>

<style scoped>

</style>
